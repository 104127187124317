<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary gen-journal-title">
				<h5>Purchase Orders</h5>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
				>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:[`item.shipping_date`]="{ item }">
					{{formatDate(item.shipping_date)}}
				</template>
				<template v-slot:[`item.po_date`]="{ item }">
					{{formatDate(item.po_date)}}
				</template>
				<template v-slot:[`item.is_paid`]="{ item }">
					<template v-if="item.is_paid == 1">
						Paid
					</template>
					<template v-else>
						Not Paid
					</template>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<template>
						<template>
							<v-icon color="primary" class="mr-2" small @click="viewItem(item)">
								mdi-clipboard-check-multiple-outline
							</v-icon>
						</template>
					</template>
					<template v-if="item.status == 1 && item.edit_by == currUser.id">
						<v-icon color="primary" class="mr-2" small @click="editItem(item)"
							>edit</v-icon
						>
					</template>
					<template v-else-if="item.status == 10 && item.edit_by == currUser.id">
						<v-icon color="primary" class="mr-2" small @click="editItem(item)"
							>edit</v-icon
						>
					</template>
					<template v-else>
						<v-icon color="red" class="mr-2" small
							>mdi-pen-off</v-icon>
					</template>
					<template>
						<v-icon color="primary" v-if="item.status == 1 && item.edit_by == currUser.id || item.status == 10 && item.edit_by == currUser.id" class="mr-2"  small @click="cancelTransaction=true; po_number=item.po_number"
							>mdi-close-circle-outline</v-icon>
							<v-icon class="mr-2"  small color="red" v-else>mdi-close-circle-outline</v-icon>
					</template>
				</template>
			</v-data-table>
			<v-btn
				bottom
				fixed
				right
				fab
				dark
				@click="setBatchDocument"
				class="mx-2"
				color="primary"
				>
				<v-icon dark>mdi-plus</v-icon>
			</v-btn>
			<div class="text-center">
				<v-dialog
					v-model="viewDialog"
					persistent
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
					>
					<v-trx-detail ref="detailTrxdisb" :poDataProp="poDataProp"></v-trx-detail>
				</v-dialog>
			</div>
			<div class="text-center">
			<v-dialog
				v-model="cancelTransaction"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Cancel PO - {{po_number}}
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to cancel Purchase Order {{po_number}}?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="cancelPayment = true"
						>
							Yes
						</v-btn>
						<v-btn
							color="red"
							text
							@click="cancelTransaction = false"
						>
							No
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="cancelPayment"
				persistent max-width="600"
			>
				<v-card>
				<v-card-title
					class="headline primary white--text"
					primary-title
				>
					Reason for Cancellation
				</v-card-title>
				<br>
				<v-card-text>
					Please state the reason:
					<v-text-field v-model="approve_remarks" label="Remarks" required></v-text-field>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
					color="primary"
					text
					@click="cancelRequest(po_number)"
					>
					Cancel Purchase Order
					</v-btn>
					<v-btn
					color="red"
					text
					@click="cancelPayment = false"
					>
					Close
					</v-btn>
				</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		</v-card>
	</v-container>
</template>

<script>
import purchaseOrderDetail from '@/components/pages/trx/purchasing/detailTrxPurchaseOrder.vue';
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import numeral from 'numeral';

export default {
	components: {
		'v-trx-detail': purchaseOrderDetail
	},
	data() {
		return {
			po_number: '',
			cancelTransaction: false,
			approve_remarks: '',
			cancelPayment: false,
			poDataProp: [],
			module_name: 'Purchase Order',
			role_access: 1,
			trxnStatus:1,
			grid_data: [],
			search: '',
			month_year: '',
			month_year_picker: false,
			batchDetails: [],
			documentDetails:[],
			data: [],
			headers: [
				{
					text: 'PO Number',
					align: 'start',
					sortable: false,
					value: 'po_number',
				},
				{ text: 'Shipping Date', value: 'shipping_date' },
				{ text: 'PO Date', value: 'po_date' },
				{ text: 'Preparer', value: 'preparer' },
				{ text: 'Is Paid', value: 'is_paid'},
				{ text: 'PO Amount', value: 'total_amount'},
				{ text: 'Actions', value: 'action', sortable: false }
			],
		};
	},
	computed: {
		...mapGetters({
			viewDialog: 'trxpurchaseorder/viewDialog',
			currUser: 'auth/currUser',
		}),
		
		filteredItems(){
			let data = this.grid_data;
			data.map(det => {
				det.total_amount = numeral(det.amount).format('0,0.00');
			});
			return data;

		}
	},
	async mounted() {
		this.$store.commit('app/SET_IS_LOADING', true, { root: true });
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		const res = await that.$store.dispatch('trxpurchaseorder/getGridPO', that.month_year + '-01');
		that.grid_data = res.data.data;
		this.$store.commit('app/SET_IS_LOADING', false, { root: true });
	},
	methods: {
		async cancelRequest (item) {
			const self = this;
			var payload = {
				status: 20,
				edit_by: this.currUser.id,
				_method: 'PUT',
				approve_remarks: this.approve_remarks,
				po_number: item
			};
			const resDetail = await self.$store.dispatch('trxpurchaseorder/updateRequestStatus', payload);
			if(resDetail.status == 200){
				this.$swal.fire({
					type: 'success',
					title: 'Sucessfully Cancelled Purchase Order!',
					timer: 3000
				}).then(() => {
					window.location.href = '/trx-purchase-order';
				});
			} else {
				this.$swal.fire({
					type: 'error',
					title: 'Unable to Cancel Purchase Order ' + item + '!',
					timer: 3000
				});
				this.cancelTransaction = false;
			}
		},
		formatDate(date){
			return format(new Date(date), 'yyyy-MM-dd');
		},
		viewItem(item){
			this.$store.commit('trxpurchaseorder/VIEW_DIALOG');
			this.poDataProp = item;
		},
		editItem(item) {
			let toPass = item.po_number;
			window.location.href = 'trx-purchase-order/edit/'+toPass;
		},
		setBatchDocument(){
			// window.open('trx-purchase-order/create', '_blank'); 
			this.$router.push('trx-purchase-order/create');
		},
		async getData(){
			const that = this;
			var date = that.month_year + '-01';
			const res = await that.$store.dispatch('trxpurchaseorder/getGridPO', date);
			that.grid_data = res.data.data;
		}
	}
};

</script>

<style scoped>
	.gen-journal-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
