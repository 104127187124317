<template>
	<v-card>
		<v-card-title
			class="headline primary dark white--text"
			primary-title
			>
			PO No. {{poDataProp.po_number}} details
			<v-spacer/>
			<v-btn icon dark @click="printPO()">
			<v-icon>mdi-printer-eye</v-icon>
			</v-btn>
			<v-btn icon dark @click="closeDialog()">
			<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<br>
			<v-layout v-if="reason">
				<v-flex md12 xs12 sm12>
					<v-alert type="error">
						This PO has been cancelled: Reason - {{reason}}
					</v-alert>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs12 sm12 md4>
					<h2>Purchase Order Information:</h2>
					<br>
					<h3>Partial Payment?: {{poDataProp.is_partial_payment ? 'Yes' : 'No'}}</h3>
					<h3 v-if="poDataProp.is_partial_payment">Down Payment: PHP {{formatAmount(down_payment)}}</h3>
					<h3>Subject To VAT: {{poDataProp.subject_to_vat ? 'Yes' : 'No'}}</h3>
					<h3>Is Delivered?: {{poDataProp.delivered_flag ? 'Yes' : 'No'}}</h3>
					<h3>Payment Mode: {{getPaymentMode(poDataProp.payment_mode)}}</h3>
					<h3>Payment Type: {{poDataProp.payment_type}}</h3>
					<h3>Shipping Date: {{poDataProp.shipping_date}}</h3>
					<h3>Vendor: {{supplier_name}}</h3>
					<h3>Contact Person: {{contact_person}}</h3>
					<h3>Address: {{address}}</h3>
					<h3>Business Style: {{business_style}}</h3>
					<h3>TIN: {{tin}}</h3>
					<h3>Contact Number: {{contact_number}}</h3>
					<h3>Email Address: {{email_add}}</h3>
					<h3>Remarks: {{poDataProp.remarks}}</h3>
					<br>
				</v-flex>
				<v-spacer/>
				<v-flex xs12 sm12 md6>
					<h3>Attached Files:</h3>
					<v-layout column style="height: 100px">
					<v-flex style="overflow: auto" class="elevation-3">
					<v-data-table
						:headers="attachHeaders"
						:items="upload_files"
						:loading="false"
						class="elevation-1"
						:hide-default-footer="true"
						:hide-default-header="true"
						style="margin:0"
						>
						<template v-slot:[`item.filename`]="{ item }">{{item.title}}</template>
						<template v-slot:[`item.file`]="{ item }">
							<v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)">
								mdi-eye-circle
							</v-icon>
						</template>
					</v-data-table>
					</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<h2>PO Items</h2>
			<v-container class="doc-items-container" grid-list-md>
				<p class="caption"></p>
				<v-row justify="center">
					<v-container outlined>
						<v-card outlined>
							<template>
								<v-card-title dark class="document-card-title grey lighten-2">
									PO No. {{poDataProp.po_number}}
								</v-card-title>
							</template>
							<v-container>
								<v-simple-table dense style="width:100%;">
									<template v-slot:default>
										<thead>
											<tr>
												<th>#</th>
												<th>Description</th>
												<th>Quantity</th>
												<th>Unit Price</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(item, index) in edit_detail_data"
												:key="index"
												>
												<td>{{ index + 1 }}</td>
												<td style="word-wrap:break-word; white-space:normal;">{{item.item_description}}</td>
												<td>{{item.quantity}}</td>
												<td>{{item.unit_cost}}</td>
												<td>{{formatAmount(item.total_amount)}}</td>
											</tr>
											<tr>
												<td><b>Total Amount</b></td>
												<td>&nbsp;</td>
												<td class="text-right"><b>PHP</b></td>
												<td class="text-right"><b>{{formatAmount(total)}}</b></td>
												<td>&nbsp;</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-container>
						</v-card>
					</v-container>
				</v-row>
			</v-container>
			<br/>
		</v-card-text>

    <v-divider></v-divider>
    <!-- <v-card-actions v-if="documentDetails[0].status!==7 && currUser.max_amount !== null">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveDisbursement = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectDisbursement = true"
      >
      Reject
      </v-btn>
    </v-card-actions> -->
	<v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
		v-if="status !== 7"
        color="success"
        text
        @click="requestForPayment"
      >
		Request For Payment
      </v-btn>
      <v-btn
        color="red"
        text
        @click="closeDialog"
      >
      Cancel
      </v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-dialog
        v-model="approveDisbursement"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            {{}} Disbursement
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to this disbursement from Batch No. ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
            >
              {{}}
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveDisbursement = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectDisbursement"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reject Disbursement
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to reject this disbursement from Batch No. {{}}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="rejectDisbursementReason = true"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectDisbursement = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectDisbursementReason"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reason for Rejection
          </v-card-title>
          <br>
          <v-card-text>
            Please state the reason:
            <v-text-field v-model="rejectRemarks" label="Remarks" required></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectDisbursementReason = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
export default {
	props: ['poDataProp'],
	data() {
		return {
			total: 0,
			same_user: '',
			reason: '',
			upload_files: [],
			edit_detail_data: '',
			vat_type: '',
			supplier_name: '',
			shipping_date: '',
			status: '',
			payee_desc: '',
			address: '',
			business_style: '',
			tin: '',
			contact_number: '',
			contact_person: '',
			down_payment: '',
			email_add: '',
			payees: [],
			snackbar: false,
			error_message: 'Error: Please fill out the required fields',
			upload_file: [],
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
		};
	},
	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			viewDialog: 'trxpurchaseorder/viewDialog',
		})
	},
	watch: {
		viewDialog: {
			handler(val) {
				if(val){
					this.init();
				}
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		checkIfSameUser () {
			if(this.currUser.id == this.poDataProp.edit_by){
				this.same_user =  true;
			} else {
				this.same_user = false;
			}
		},
		printPO () {
			// window.location.href = '/purchase-order-print/'+this.poDataProp.po_number;
			let routeData = this.$router.resolve({path: '/purchase-order-print/'+this.poDataProp.po_number});
			window.open(routeData.href, '_blank'); 
		},
		async requestForPayment () {
			if(this.poDataProp.payment_type === 'Credit Term' && this.poDataProp.delivered_flag === 0){
				this.snackbar = true;
				this.error_message = 'Purchase Order should be delivered first.';
			} else {
				const self = this;
				var payload = {
					status: '7',
					edit_by: this.currUser.id,
					_method: 'PUT',
					po_number: self.poDataProp.po_number
				};
				const resDetail = await self.$store.dispatch('trxpurchaseorder/updateRequestStatus', payload);
				if(resDetail.status == 200){
					this.$swal.fire({
						type: 'success',
						title: 'Sucessfully Requested for Payment!',
						timer: 3000
					}).then(() => {
						window.location.href = '/trx-purchase-order';
					});
				}
			}
		},

		async init () {
			this.$store.commit('app/SET_IS_LOADING', true, { root: true });
			const self = this;
			const payeeRes = await self.$store.dispatch('trxpurchaseorder/getPayefiles');
			this.payees = payeeRes.data.data;
			const res = await this.$store.dispatch('trxpurchaseorder/getPayeeFile', self.poDataProp.payee_id);
			this.tin = res.data.data[0].tin;
			this.supplier_name = res.data.data[0].payee_desc;
			this.business_style = res.data.data[0].business_style;
			this.contact_number = res.data.data[0].contact_num;
			this.email_add = res.data.data[0].email_add;
			this.address = res.data.data[0].house_unit + ' ' + res.data.data[0].street + ' ' + res.data.data[0].barangay + ', ' +  res.data.data[0].city + ' ' + res.data.data[0].zip_code;
			// const resDetail = await self.$store.dispatch('trxpurchaseorder/getDetailPO');
			// let filtered = resDetail.data.data.filter(function(e){
			// 	return e.po_number == self.poDataProp.po_number;
			// });
			// this.edit_detail_data = filtered;
			// this.computeTotalAmount();
			// console.log(this.edit_detail_data, 'detail data');
			const resHeader = await self.$store.dispatch('trxpurchaseorder/getPO', self.poDataProp.po_number);
			this.reason = resHeader.data.data[0].approve_remarks;
			this.edit_detail_data = resHeader.data.data[0].po_details;
			this.computeTotalAmount();
			this.down_payment = resHeader.data.data[0].down_payment;
			this.contact_person = resHeader.data.data[0].contact_person;
			this.status = resHeader.data.data[0].status;
			this.upload_files = JSON.parse(resHeader.data.data[0].upload_file);
			this.$store.commit('app/SET_IS_LOADING', false, { root: true });
			this.checkIfSameUser();
		},

		closeDialog () {
			this.$store.commit('trxpurchaseorder/CLOSE_DIALOG');
			this.total = 0;
		},

		computeTotalAmount () {
			const self = this;
			this.edit_detail_data.forEach(function(e){
				self.total += parseFloat(e.total_amount);
			});
		},
	
		formatAmount (amount) {
			return numeral(amount).format('0,0.00');
		},
	
		viewItem(item) {
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) 
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
		},
		getPaymentMode(mode){
			if(mode === 1){
				return 'Branch Disbursement through RFA';
			} else {
				return 'Head Office Disbursement';
			}
		}
	}
};
</script>
